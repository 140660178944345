import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { IconService } from './icon.service';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent implements OnInit {
  @Input('name') name!: string;
  @Input('light') light = false;
  @Input('spin') spin = false;
  @Input('size') size: number = 1;
  emojiIcon: string | undefined;
  customIconSafeUrl: any;
  customIcon: { name: string; url: string } | undefined;
  constructor(private icons: IconService, private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    if (this.size === 0) {
      this.size = 1;
    }
    if (!/\p{Emoji}/u.test(this.name)) {
      this.customIcon = this.icons.list.find((el) => {
        return el.name == this.name;
      });
      if (this.customIcon) {
        this.customIconSafeUrl = this.sanitizer.bypassSecurityTrustStyle(
          "background-image: url('" + this.customIcon.url + "')"
        );
      }
    } else {
      this.emojiIcon = this.name;
    }
  }
}
