<div [ngSwitch]="layout" #spinner>
  <div *ngSwitchCase="'fancy'" class="fancy-wrapper">
    <div class="loader">
      <div class="tall-stack">
        <!-- <div class="butter falling-element"></div>
        <div class="pancake falling-element"></div>
        <div class="pancake falling-element"></div>
        <div class="pancake falling-element"></div>
        <div class="pancake falling-element"></div>
        <div class="pancake falling-element"></div>
        <div class="pancake falling-element"></div> -->
        <div class="plate">
          <svg viewBox="0 0 1200 1200">
            <style>
              .cryptopoop-skeleton{
                fill: #fff

              }
              .cryptopoop-background {
                fill: #ff2;
              }
              .cryptopoop-bottombody {
                fill: {{poocolors[0]}};
              }
              .cryptopoop-middlebody {
                fill:{{poocolors[1]}};
              }
              .cryptopoop-topbody {
                fill: {{poocolors[2]}};
              }
            </style>

            <g class="cryptopoop-topbody butter falling-element">
              <svg x="0" y="0">
                <svg height="1200" width="1200">
                  <path
                    d="m 564.60771,532.48753 c -46.06535,-2.26826 -82.10163,-8.80594 -117.15529,-21.25424 -17.63667,-6.26316 -52.69606,-22.36633 -55.43232,-25.46069 -1.38704,-1.56856 -1.46742,-2.75046 -1.47518,-21.68988 -0.008,-20.56201 0.37534,-24.69732 3.43325,-36.99548 4.22557,-16.9942 14.47406,-34.11253 29.38125,-49.07624 17.59995,-17.6667 37.6968,-27.8507 61.7622,-31.29774 13.0107,-1.86361 42.54942,-3.33481 67.03487,-3.3387 17.16355,-0.003 24.60528,-0.28657 29.50355,-1.12529 25.57455,-4.379 46.10865,-18.11577 61.60186,-41.21002 13.85614,-20.65403 21.78134,-50.00954 19.13793,-70.88827 -0.46224,-3.65106 -1.14055,-8.95656 -1.50734,-11.79 -0.3668,-2.83344 -0.58371,-5.23491 -0.48202,-5.3366 0.10168,-0.10159 2.98341,0.30433 6.40384,0.90226 13.60071,2.37753 31.49648,14.38854 49.00461,32.89014 45.10446,47.66387 70.05844,101.18371 79.40959,170.31301 1.56892,11.59832 1.17242,38.88309 -0.74492,51.26244 -2.05066,13.24029 -3.32837,17.9297 -5.46827,20.0696 -3.25996,3.25995 -39.39949,17.08622 -62.90679,24.06688 -34.3286,10.19414 -68.01513,16.02027 -109.132,18.87452 -11.49246,0.7978 -44.36494,1.47842 -52.36882,1.0843 z"
                  />
                </svg>
              </svg>
            </g>

            <g class="cryptopoop-middlebody pancake falling-element">
              <svg x="0" y="0">
                <svg height="1200" width="1200">
                  <path
                    d="m 561.49496,736.36315 c -62.44714,-3.60461 -147.35043,-20.0636 -216.67755,-42.00415 -26.46658,-8.3761 -64.62363,-23.35833 -67.73674,-26.59659 -2.13349,-2.21924 -4.10743,-13.6484 -4.78023,-27.67739 -1.72911,-36.05503 10.91229,-69.18499 36.91176,-96.73657 12.23968,-12.97036 24.48759,-22.20292 39.49338,-29.77036 20.86088,-10.52017 43.14827,-15.68483 54.08102,-12.53218 1.74968,0.50455 8.43028,3.32833 14.84577,6.27507 46.94058,21.5605 92.14769,32.89391 145.2644,36.41775 14.41277,0.95616 51.36813,0.95592 64.69721,-4.2e-4 58.48475,-4.19622 110.20334,-16.98396 164.71735,-40.72742 5.02666,-2.18936 6.86187,-2.61942 11.22594,-2.63067 14.07423,-0.0363 43.08305,10.2084 60.1475,21.24158 16.16171,10.44949 31.04722,25.90709 40.96298,42.53726 13.13281,22.02561 20.56857,47.16369 20.56857,69.53614 0,13.72775 -2.9868,31.31041 -5.90055,34.73525 -3.5585,4.18268 -50.81745,22.81226 -86.00184,33.90211 -55.31383,17.43449 -110.09411,27.87667 -168.959,32.20684 -33.13866,2.43773 -78.33941,3.23917 -102.85997,1.82375 z"
                  />
                </svg>
              </svg>
            </g>

            <g class="cryptopoop-bottombody pancake falling-element">
              <svg x="0" y="0">
                <svg height="1200" width="1200">
                  <path
                    d="m 344.00841,1049.5944 c -24.12062,-1.941 -42.00175,-7.0332 -63.90725,-18.1995 -20.6363,-10.5196 -35.18981,-20.9547 -49.99072,-35.84464 -30.02075,-30.20123 -47.72966,-67.9605 -55.23381,-117.77053 -1.82563,-12.118 -1.83367,-45.86809 -0.0137,-57.6283 5.96736,-38.55986 22.31674,-73.77697 48.69016,-104.88023 9.2414,-10.89876 32.46539,-31.00869 40.97041,-35.47676 5.64181,-2.96391 9.10785,-2.32235 29.33386,5.4296 79.66106,30.53144 165.75607,49.7574 254.6661,56.86975 87.90721,7.03213 187.96407,-2.46419 269.29836,-25.55889 28.38108,-8.05873 44.1583,-13.5987 78.13395,-27.43571 27.26571,-11.10432 27.15001,-11.06501 30.09214,-10.22281 5.14963,1.47408 20.97101,12.93957 30.92896,22.41361 18.47373,17.57604 32.64692,37.12064 43.92423,60.57065 11.7007,24.3303 18.0365,49.46481 19.4732,77.25238 2.7019,52.24583 -11.914,100.27135 -42.70557,140.32445 -6.09744,7.93138 -20.00511,22.36183 -27.74633,28.78933 -21.31815,17.7001 -48.60891,31.4459 -74.07186,37.3081 -20.60144,4.743 0.68028,4.3924 -274.77132,4.5277 -137.3504,0.068 -253.03227,-0.1433 -257.07081,-0.4682 z"
                  />
                </svg>
              </svg>
            </g>
            <g class="cryptopoop-skeleton">
              <path
                d="M597.178,1069.779c-76.489,0-152.977-0.014-229.466,0.005
        c-51.989,0.013-98.385-15.854-137.941-49.813c-41.882-35.956-67.033-81.572-74.675-136.274
        c-8.821-63.147,7.075-119.96,46.41-170.014c12.597-16.029,27.404-30.063,44.357-41.545c4.934-3.342,6.532-6.729,5.5-12.797
        c-13.848-81.463,39.986-154.57,116.889-176.281c10.352-2.923,10.637-2.927,9.975-13.613c-1.827-29.477,7.583-55.517,22.568-80.423
        c24.249-40.305,60.677-60.144,106.249-64.674c22.169-2.204,44.563-4.569,66.74-3.744c38.821,1.442,72.575-42.43,62.771-80.887
        c-0.898-3.521-1.277-7.193-1.691-10.818c-2.887-25.265,21.024-34.936,37.963-30.201c18.637,5.21,34.514,15.018,48.551,27.815
        c52.973,48.298,81.463,109.369,92.598,179.317c3.557,22.342,3.845,44.771,1.229,67.244c-0.761,6.53-0.378,7.068,6.37,8.844
        c24.188,6.364,46.377,16.87,65.517,33.031c39.938,33.723,59.927,76.549,57.526,129.193c-0.242,5.308-0.732,10.719-2.065,15.833
        c-1.511,5.798,0.802,8.922,5.293,11.951c17.696,11.933,32.834,26.745,46.012,43.396c25.736,32.52,41.316,69.551,45.772,110.784
        c7.429,68.745-12.435,128.727-59.952,179.144c-29.424,31.22-65.357,51.385-107.417,59.817c-13.958,2.799-28.39,4.429-42.616,4.507
        C752.156,1070.004,674.667,1069.779,597.178,1069.779z M596.508,1041.056c76.996,0,153.992,0.052,230.987-0.04
        c17.701-0.021,35.219-1.757,52.389-6.535c74.274-20.668,129.699-89.063,133.966-171.609c2.486-48.095-10.974-91.609-40.563-129.754
        c-13.795-17.782-30.329-32.757-49.9-44.091c-1.996-1.156-5.217-1.729-7.286-0.975c-11.705,4.271-23.229,9.035-34.819,13.622
        c-77.675,30.74-158.775,44.341-241.805,48.051c-50.253,2.245-100.258-1.114-150.021-8.047
        c-69.991-9.751-138.101-27.01-204.023-52.547c-7.281-2.82-12.471-2.888-19.292,2.059c-14.251,10.335-27.984,21.14-39.288,34.591
        c-42.766,50.887-56.444,109.318-40.924,173.491c14.575,60.264,51.252,103.375,108.391,128.324
        c22.478,9.815,46.309,13.414,70.698,13.433C442.181,1041.089,519.345,1041.051,596.508,1041.056z M593.542,731.041
        c72.151-0.147,134.067-6.924,195.004-22.025c41.267-10.228,81.477-23.591,120.489-40.487c2.072-0.897,4.377-3.218,4.948-5.318
        c6.085-22.335,5.362-44.808-2.217-66.451c-17.979-51.342-55.643-79.733-108.171-89.599c-4.484-0.843-9.93-0.413-14.082,1.383
        c-76.796,33.231-156.908,45.54-240.105,38.531c-47.903-4.036-93.672-16.379-137.166-36.813c-8.021-3.769-15.852-4.438-24.2-2.803
        c-31.273,6.126-57.808,20.779-79.188,44.465c-28.242,31.285-37.525,67.777-29.649,108.957c0.703,3.675,2.361,5.619,5.839,7.09
        c38.27,16.189,77.853,28.115,118.262,37.645C468.996,721.106,535.26,731.707,593.542,731.041z M662.227,226.084
        c0.457,4.119,0.572,6.754,1.063,9.317c2.456,12.834,1.708,25.531-1.402,38.147c-9.523,38.619-39.826,78.352-94.38,75.509
        c-19.869-1.035-39.932,0.574-59.851,1.851c-34.838,2.231-61.96,18.434-81.938,46.952c-6.386,9.115-11.061,18.934-13.886,29.69
        c-4.688,17.853-3.887,35.992-2.782,54.122c0.087,1.436,2.071,3.304,3.615,4.019c14.327,6.639,28.448,13.875,43.2,19.418
        c64.249,24.146,130.712,26.378,197.956,18.32c44.239-5.301,86.616-17.678,127.418-35.557c2.93-1.284,4.29-2.917,5.006-5.935
        c5.339-22.493,6.031-45.265,2.902-68.003c-9.221-66.995-38.163-123.974-88.397-169.57c-6.774-6.147-15.276-10.597-23.475-14.884
        C673.136,227.316,667.885,227.276,662.227,226.084z"
                clip-rule="evenodd"
                [attr.fill]="getSkeletonColor()"
                fill-rule="evenodd"
              ></path>
            </g>
          </svg>

          <div class="shadow"></div>
        </div>
      </div>
    </div>
  </div>
  <div *ngSwitchCase="'simple'">
    <div class="content-spinner">
      <div class="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>

  <div *ngSwitchDefault>
    <div class="loader-simple">
      <div class="loader-inner">
        <div class="loader-line-wrap">
          <div class="loader-line"></div>
        </div>
        <div class="loader-line-wrap">
          <div class="loader-line"></div>
        </div>
        <div class="loader-line-wrap">
          <div class="loader-line"></div>
        </div>
        <div class="loader-line-wrap">
          <div class="loader-line"></div>
        </div>
        <div class="loader-line-wrap">
          <div class="loader-line"></div>
        </div>
      </div>
      <h3 *ngIf="loadingMessage" class="textCenter mt-6">{{loadingMessage}}</h3>
    </div>
  </div>
</div>
