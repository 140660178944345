import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, Meta } from '@angular/platform-browser';

import { iconlist } from './ui/icon/cusotom-icon-binding';
import { PrimeNGConfig } from 'primeng/api';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  Event as RouterEvent,
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import AOS from 'aos';
import {
  NgcCookieConsentService,
  NgcNoCookieLawEvent,
  NgcStatusChangeEvent,
  NgcInitializingEvent,
} from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';
import {
  fadeOutOnLeaveAnimation,
  slideInDownOnEnterAnimation,
  slideOutUpOnLeaveAnimation,
} from 'angular-animations';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    slideInDownOnEnterAnimation({ anchor: 'enter', duration: 300 }),
    slideOutUpOnLeaveAnimation({ anchor: 'leave', duration: 300 }),
  ],
})
export class AppComponent implements OnInit {
  showPhishingBar: boolean = true;

  iconlist = iconlist;
  public showOverlay = true;
  @ViewChild('app') appRef?: ElementRef<any>;
  title = 'CRYPTOPOOPZ';
  sizing: { innerWidth: number; innerHeight: number } = {
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth - 35,
  };

  //keep refs to subscriptions to be able to unsubscribe later
  private popupOpenSubscription?: Subscription;
  private popupCloseSubscription?: Subscription;
  private initializeSubscription?: Subscription;
  private statusChangeSubscription?: Subscription;
  private revokeChoiceSubscription?: Subscription;
  private noCookieLawSubscription?: Subscription;

  domain: string = environment.domain;

  constructor(
    // private matIconRegistry: MatIconRegistry,
    translate: TranslateService,
    private router: Router,
    public domSanitizer: DomSanitizer,
    private meta: Meta,
    private primengConfig: PrimeNGConfig,
    private ccService: NgcCookieConsentService
  ) {
    router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });
    translate.setDefaultLang('en');

    translate.use('en');

    this.showPhishingBar = sessionStorage.getItem('no-phishing') == null;
  }

  closePhishingBar() {
    sessionStorage.setItem('no-phishing', 'true');
    this.showPhishingBar = false;
  }
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.showOverlay = true;
    }
    if (event instanceof NavigationEnd) {
      this.showOverlay = false;
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.showOverlay = false;
    }
    if (event instanceof NavigationError) {
      this.showOverlay = false;
    }
  }
  ngOnInit() {
    this.meta.addTags([
      {
        charset: 'UTF-8',
      },
      {
        name: 'title',
        content: 'CryptoPoopz',
      },
      {
        name: 'description',
        content:
          'Make NFTs Great Again - There are infinite possibilities for CRYPTOPOOPZ. Dynamic NFTs are here to evolve with composability, advanced utility, gamification, reward systems, governance tokens, and much more to come.',
      },
      {
        name: 'author',
        content: 'Hybris Lab',
      },
      {
        property: 'og:title',
        content: 'CryptoPoopz',
      },
      {
        property: 'og:description',
        content:
          'Make NFTs Great Again - There are infinite possibilities for CRYPTOPOOPZ. Dynamic NFTs are here to evolve with composability, advanced utility, gamification, reward systems, governance tokens, and much more to come.',
      },
      {
        property: 'og:url',
        content: environment.domain,
      },
      {
        property: 'og:image',
        content: environment.domain + '/assets/ogimage.png',
      },
      {
        property: 'og:type',
        content: 'website',
      },
      {
        name: 'keywords',
        content:
          'NFT, composable, dynamic, avalanche, store of value, gamifi, poo, poopz',
      },
      {
        name: 'keywords',
        content:
          'NFT, composable, dynamic, avalanche, store of value, gamifi, poo, poopz',
      },
      {
        name: 'robots',
        content: 'index, follow',
      },
      {
        name: 'googlebot',
        content: 'index, follow',
      },
      {
        name: 'msnbot',
        content: 'index, follow',
      },
      {
        httpEquiv: 'Content-Type',
        content: 'text/html; charset=utf-8',
      },
      {
        name: 'language',
        content: 'English',
      },
      {
        name: 'revisit-after',
        content: '15 days',
      },
    ]);
    this.primengConfig.ripple = true;
    AOS.init();
    AOS.refresh();

    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(() => {
      // you can use this.ccService.getConfig() to do stuff...
    });

    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(() => {
      // you can use this.ccService.getConfig() to do stuff...
    });

    this.initializeSubscription = this.ccService.initialized$.subscribe(
      (event: any) => {
        // you can use this.ccService.getConfig() to do stuff...
      }
    );

    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      }
    );

    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      }
    );

    this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
      (event: NgcNoCookieLawEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      }
    );
  }
  ngAfterViewInit() {}
  ngOnDestroy() {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.popupOpenSubscription?.unsubscribe();
    this.popupCloseSubscription?.unsubscribe();
    this.initializeSubscription?.unsubscribe();
    this.statusChangeSubscription?.unsubscribe();
    this.revokeChoiceSubscription?.unsubscribe();
    this.noCookieLawSubscription?.unsubscribe();
  }
}
