<span [class.rotate]="spin" class="align-items-center flex">
  <ng-container *ngIf="emojiIcon; then emoji; else icon"> </ng-container>
</span>

<ng-template #icon>
  <ng-container *ngIf="customIcon; then custom; else primeIcon"> </ng-container>

  <ng-template #custom>
    <i
      class="pi custom-svg-icon"
      [style]="customIconSafeUrl"
      [style.height.rem]="size"
      [style.width.rem]="size"
      [class.light]="light"
    ></i>
  </ng-template>
  <ng-template #primeIcon>
    <i
      class="pi pi-{{ name }}"
      [style.font-size.rem]="size"
      [class.light]="light"
    ></i>
  </ng-template>
</ng-template>

<ng-template #emoji>
  <i
    class="pi emoji-icon no-underline"
    [attr.data-content]="emojiIcon"
    [style.font-size.rem]="size"
  ></i>
</ng-template>
