<div class="specialbuttonframe"><svg
  width="6.5917816mm"
  height="10.434723mm"
  viewBox="0 0 6.5917816 10.434723"
  version="1.1"
  id="svg5"
  class="absolute overflow-visible right-0"
>
  <defs id="defs2" />
  <g id="layer1" transform="translate(-161.1881,-81.136259)">
    <path
      class="dash-array-path"
      d="m 165.64599,81.229805 2.0016,2.001607 v 5.162746 l -3.04453,3.044534 h -3.41497"
      id="path708"
    />
  </g>
</svg>

<svg
  width="40.212326mm"
  height="2.1121445mm"
  viewBox="0 0 40.212326 2.1121445"
  version="1.1"
  id="svg5"
  class="absolute  overflow-visible bottom-0 "

>
  <defs id="defs2" />
  <g id="layer1" transform="translate(-86.618349,-92.171392)">
    <path
      class="dash-array-path"
      d="m 126.76453,92.573897 -2.73206,1.577353 H 89.915271 l -3.230774,-1.865286"
      id="path710"
    />
  </g>
</svg>
<svg
  width="1.7911644mm"
  height="9.5103912mm"
  viewBox="0 0 1.7911644 9.5103912"
  version="1.1"
  id="svg5"
  class="absolute overflow-visible left-0"

>
  <defs id="defs2" />
  <g id="layer1" transform="translate(-32.034917,-80.945699)">
    <path
      class="dash-array-path"
      d="m 33.677984,90.34152 -0.966118,-0.557789 v -1.940724 l -0.544658,-0.943375 v -2.900724 -1.394336 l 1.565328,-1.565328"
      id="path712"
    />
  </g>
</svg>
</div>
