import { Injectable } from "@angular/core";
import { iconlist } from "./cusotom-icon-binding"

@Injectable({
  providedIn: 'root',
})
export class IconService {
  list = iconlist
  constructor() {




  }

  loadIcons() {

  }

}
