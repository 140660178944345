<div *ngIf="showPhishingBar" [@enter] [@leave]
  class="no-phishing-bar justify-content-center align-items-center flex  gap-3">

  <app-icon name="times" class="mr-1 pointer" [light]="true" (click)="closePhishingBar()"></app-icon>

  <span class="flex md:flex-grow-0 flex-grow-1 flex-column md:flex-row  justify-content-center   gap-1 ">
   <span class="flex gap-1 "> <app-icon name="danger" [light]="true"></app-icon> Make sure the URL is</span>

    <span class="w-min"><a [href]="domain">{{domain}}</a></span>
  </span>

</div>

<div class="wrapperContainer">
  <app-homeframe *ngIf="!showOverlay" >

  </app-homeframe>
  <div

    *ngIf="showOverlay; then overlayLoading; else app"
  ></div>
  <ng-template #overlayLoading>
    <app-loading-spinner [layout]="'fancy'"></app-loading-spinner>
  </ng-template>
  <ng-template #app>
   <div class="mainContainer"><router-outlet></router-outlet></div>
  </ng-template>

</div>
