import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-homeframe',
  templateUrl: './homeframe.component.html',
  styleUrls: ['./frame.component.scss', './homeframe.component.scss'],
})
export class HomeFrameComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

  }

  calculateFrameSize(size: number): number {
    let newSize = 0;
    if (window.innerWidth < 1400) {
      newSize = size * (Math.log10(window.innerWidth) / 1400) * 360;
    } else {
      newSize = size;
    }
    if (newSize > window.innerHeight) {
      newSize = window.innerHeight;
    }
    return newSize;
  }
}
@Component({
  selector: 'app-bgframe',
  templateUrl: './bgframe.component.html',
  styleUrls: ['./frame.component.scss', './bgframe.component.scss'],
})
export class BgFrameComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

  }


}
@Component({
  selector: 'app-simpleframe',
  templateUrl: './simpleframe.component.html',
  styleUrls: ['./frame.component.scss', './simpleframe.component.scss'],
})
export class SimpleFrameComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

  }


}
@Component({
  selector: 'app-specialbuttonframe',
  templateUrl: './specialbuttonframe.component.html',
  styleUrls: ['./frame.component.scss', './specialbuttonframe.component.scss'],
})
export class SpecialButtonFrameComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
