<div class="bgWrapper">
  <svg
  class="deco-left hidden md:block"
  [attr.width]="100.9718"
  [attr.height]="1407.8505"
  viewBox="0 0 100.9718 1407.8505"
  fill="none"
  version="1.1"
  id="svg86"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:svg="http://www.w3.org/2000/svg"
>
  <path
    class="path dash-array-path"
    d="m 39.557583,341.57927 -1.4955,-277.6653 c -0.0029,-0.5341 0.208,-1.0472 0.5858,-1.425 l 60.9097,-60.9097002"
    id="path74"
  />
  <path
    class="path regular-path"
    d="M 69.804673,1.555635 18.063471,53.296838 V 326.83029 l 25.585996,25.58599 v 201.48524 l -24.185591,24.18559 v 798.25109 l -17.5586203,30.4124"
    id="path1381"
  />

  <path
    class="path dash-array-path"
    d="m 42.892468,555.18047 v 373.76335 l -30.19619,30.19619 v 320.56369 l 8.061606,15.6383"
    id="path1393"
  />
</svg>
<svg class="deco-left  md:hidden " [style.top.px]="900" xmlns="http://www.w3.org/2000/svg" width="40.852631"
  height="645.16541" viewBox="0 0 40.852631 645.16541">
  <path class="path regular-path"

    d="M 0.58827861,0.17877004 22.422889,57.22146 v 549.60633 l -21.98987039,38.0876" id="path1010" />
  <path class="path dash-array-path"
    d="m 21.402029,93.25899 16.95061,29.35931 V 295.13115 L 3.8594686,331.62432 v 229.42307 l 18.9771504,32.8694"
    id="path1012" />
</svg>
<svg
  class="deco-right hidden md:block"
  [attr.width]="98.763184"
  [attr.height]="1419.1996"
  viewBox="0 0 98.763184 1419.1996"
  fill="none"
  version="1.1"
  id="svg86"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:svg="http://www.w3.org/2000/svg"
>
  <path
    class="path regular-path"
    d="m 3.1812547,0.0045848 -0.4266,204.7030552 50.0322003,123.62045 -1.0698,1037.15211 30.3798,52.6195"
    id="path1387"
  />

  <path
    class="path dash-array-path"
    d="M 1.9999547,211.02555 V 683.80384 L 52.662655,771.55422"
    id="path1391"
  />
  <path
    class="path dash-array-path"
    d="m 51.551555,981.02962 23.3639,40.46748 v 345.8659 l 23.4148,40.5557"
    id="path3148"
  />
</svg>
<svg class="deco-right md:hidden" width="172.59872" height="326.82373" viewBox="0 0 172.59872 326.82373" fill="none"
  xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg">

  <path class="path regular-path"
    d="M 0.35356301,0.35355354 13.532133,13.532125 H 130.45052 v 33.212684 l 10.91118,10.911177 v 65.640344 l 12.07559,12.07558 v 157.5685 l 18.83867,15.9223"
    id="path705" />
  <path class="path dash-array-path" d="m 43.721223,14.79986 13.1721,13.172103 h 73.076097" id="path707" />
  <path class="path dash-array-path" d="M 137.16482,54.188425 V 166.91849 l 17.1016,17.1016" id="path709" />
  <path class="path dash-array-path" d="m 154.53744,254.08845 -7.988,7.988 v 42.94684 l 25.49905,21.41757" id="path711" />
  </svg>
<svg
  [attr.width]="calculateFrameSize(1058.6484)"
  [attr.height]="calculateFrameSize(80.441132)"
  viewBox="0 0 1058.6484 80.441132"
  fill="none"
  version="1.1"
  id="svg86"
  class="deco-top hidden md:block"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:svg="http://www.w3.org/2000/svg"
>
  <path
    class="path regular-path"
    d="M 1.2144337,2.5614387 50.530604,40.250218 651.87095,41.763403 691.81355,23.187361 h 328.41105 l 19.3593,-20.4505969"
    id="path3195"
    sodipodi:nodetypes="cccccccc"
  />
  <path
    class="path dash-array-path"
    d="M 14.234654,0.4052533 43.915424,21.854685 184.25727,22.144277 202.2033,51.851318"
    id="path3197"
  />
  <path
    class="path dash-array-path"
    d="m 202.02158,51.766398 92.15572,0.124696 h 63.18463 L 403.6605,5.5925289"
    id="path3199"
  />
  <path
    class="path dash-array-path"
    d="m 575.98835,6.0068185 27.6825,27.6825005 h 164.5332 l 11.6181,-11.618092"
    id="path3201"
  />

  <path
    class="path dash-array-path"
    d="m 992.73915,25.026923 13.83975,13.839744 h 24.4841 l 26.8652,-32.1726693"
    id="path3205"
  />
</svg>
</div>
  <!-- <div *ngIf="!showOverlay" class="bgWrapperBottom">
    <svg
      class="deco-bottom hidden lg:block"
      [attr.width]="calculateFrameSize(1387.2271)"
      [attr.height]="calculateFrameSize(60.458374)"
      viewBox="0 0 1387.2271 60.458374"
      fill="none"
      version="1.1"
      id="svg86"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:svg="http://www.w3.org/2000/svg"
    >
      <path
        class="path dash-array-path"
        d="m 1299.187,2.0033717 -7.1428,9.4831003 c -0.2884,0.3829 -0.6756,0.5946 -1.0779,0.5946 h -147.5174 c -0.4023,0 -0.7894,0.2116 -1.0779,0.5945 l -24.1535,32.067 c -0.2885,0.3829 -0.6756,0.5946 -1.0779,0.5946 h -54.911 c -0.4024,0 -0.7895,-0.2117 -1.0779,-0.5946 l -7.1429,-9.483"
        id="path82"
      />

      <path
        class="path regular-path"
        d="m 1.7645522,59.691072 8.5382698,-19.6339 H 176.11263 l 9.4898,12.5989 h 218.33701 l 15.65317,-16.7506 H 1116.5985 L 1142.194,1.9241717 h 218.3084 l 24.9759,54.4141003"
        id="path1385"
      />

      <path
        class="path dash-array-path"
        d="m 243.58967,52.838872 15.90044,-21.1098 H 393.73328 L 413.1155,5.9966717 h 228.95402 l 33.5133,44.4931003 h 164.8964 l 11.7263,-15.568"
        id="path1389"
      />
    </svg>
  </div> -->
