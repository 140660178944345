import { NgModule } from '@angular/core';
import { SpeeddialComponent } from './speeddial.component';
import { IconService } from '../icon/icon.service';
import { CommonModule } from '@angular/common';
import { IconModule } from '../icon/icon.module';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PrimengModule } from 'src/app/primeng.module';

@NgModule({
  declarations: [SpeeddialComponent],
  providers: [IconService],
  exports: [SpeeddialComponent],
  imports: [CommonModule, IconModule, RouterModule, PrimengModule],
})
export class SpeeddialModule {}
