import { NgModule } from '@angular/core';
import {
  BgFrameComponent,
  HomeFrameComponent,
  SimpleFrameComponent,
  SpecialButtonFrameComponent,
} from './frame.component';

@NgModule({
  declarations: [
    HomeFrameComponent,
    BgFrameComponent,
    SimpleFrameComponent,
    SpecialButtonFrameComponent,
  ],
  providers: [],
  exports: [
    HomeFrameComponent,
    BgFrameComponent,
    SimpleFrameComponent,
    SpecialButtonFrameComponent,
  ],
  imports: [],
})
export class FrameModule {}
