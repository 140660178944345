export const iconlist = [
  {
    name: 'hat',
    url: `/assets/icons-svgs/hat.svg`,
  }, {
    name: 'extra',
    url: `/assets/icons-svgs/extra.svg`,
  }, {
    name: 'deco',
    url: `/assets/icons-svgs/deco.svg`,
  }, {
    name: 'background',
    url: `/assets/icons-svgs/background.svg`,
  }, {
    name: 'drink',
    url: `/assets/icons-svgs/drink.svg`,
  }, {
    name: 'eyewear',
    url: `/assets/icons-svgs/eyewear.svg`,
  }, {
    name: 'mustache',
    url: `/assets/icons-svgs/mustache.svg`,
  }, {
    name: 'portal',
    url: `/assets/icons-svgs/opened_portal.svg`,
  }, {
    name: 'poop',
    url: `/assets/icons-svgs/poop.svg`,
  },
  //fontawesome
  {
    name: 'danger',
    url: `/assets/icons-svgs/solid/triangle-exclamation.svg`,
  },
  {
    name: 'times',
    url: `/assets/icons-svgs/solid/x.svg`,
  }, {
    name: 'check',
    url: `/assets/icons-svgs/solid/check.svg`,
  },
  {
    name: 'save',
    url: `/assets/icons-svgs/solid/floppy-disk.svg`,
  },
  {
    name: 'refresh',
    url: `/assets/icons-svgs/solid/arrows-rotate.svg`,
  },
  {
    name: 'search',
    url: `/assets/icons-svgs/solid/magnifying-glass.svg`,
  },
  {
    name: 'send',
    url: `/assets/icons-svgs/solid/paper-plane.svg`,
  },
  {
    name: 'gift',
    url: `/assets/icons-svgs/solid/gift.svg`,
  },
  {
    name: 'spark',
    url: `/assets/icons-svgs/solid/hand-sparkles.svg`,
  },
  {
    name: 'lock',
    url: `/assets/icons-svgs/solid/lock.svg`,
  },
  {
    name: 'unlock',
    url: `/assets/icons-svgs/solid/lock-open.svg`,
  },
  {
    name: 'buy',
    url: `/assets/icons-svgs/solid/cart-plus.svg`,
  },
  {
    name: 'trash',
    url: `/assets/icons-svgs/solid/trash.svg`,
  },
  {
    name: 'edit',
    url: `/assets/icons-svgs/solid/pencil.svg`,
  },
  {
    name: 'rocket',
    url: `/assets/icons-svgs/solid/rocket.svg`,
  },
  {
    name: 'avax',
    url: `/assets/icons-svgs/avax.svg`,
  },
  {
    name: 'game',
    url: `/assets/icons-svgs/solid/gamepad.svg`,
  },

  {
    name: 'twitter',
    url: `/assets/icons-svgs/brands/twitter-square.svg`,
  },
  {
    name: 'facebooksquare',
    url: `/assets/icons-svgs/brands/facebook-square.svg`,
  },
  {
    name: 'fa_facebook',
    url: `/assets/icons-svgs/brands/facebook.svg`,
  },
  {
    name: 'fa_twitter',
    url: `/assets/icons-svgs/brands/twitter.svg`,
  },
  {
    name: 'fa_telegram',
    url: `/assets/icons-svgs/brands/telegram.svg`,
  },
  {
    name: 'fa_discord',
    url: `/assets/icons-svgs/brands/discord.svg`,
  },
  {
    name: 'fa_medium',
    url: `/assets/icons-svgs/brands/medium.svg`,
  },

  {
    name: 'fa_github',
    url: `/assets/icons-svgs/brands/github.svg`,
  },
  //custom icons
  {
    name: 'backpack',
    url: `/assets/icons-svgs/custom/backpack.svg`,
  },
  {
    name: 'collection',
    url: `/assets/icons-svgs/custom/collection.svg`,
  },
  {
    name: 'vault',
    url: `/assets/icons-svgs/custom/vault 2.svg`,
  },
  {
    name: 'market',
    url: `/assets/icons-svgs/custom/market 3.svg`,
  },
  {
    name: 'shop',
    url: `/assets/icons-svgs/custom/shopping-cart.svg`,
  },
  {
    name: 'market',
    url: `/assets/icons-svgs/custom/market 3.svg`,
  },
  {
    name: 'docs',
    url: `/assets/icons-svgs/custom/docs.svg`,
  },
  {
    name: 'mint',
    url: `/assets/icons-svgs/custom/mintaxe.svg`,
  },
];
