import { NgModule } from '@angular/core';
import { CardActionsComponent } from './card-actions/card-actions.component';
import { CardContentComponent } from './card-content/card-content.component';
import { CardSubtitleComponent } from './card-subtitle/card-subtitle.component';
import { CardTitleComponent } from './card-title/card-title.component';
import { CardComponent } from './card.component';
import { IconModule } from '../icon/icon.module';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
@NgModule({
  declarations: [
    CardTitleComponent,
    CardSubtitleComponent,
    CardContentComponent,
    CardActionsComponent,
    CardComponent,
  ],
  providers: [],
  exports: [
    CardTitleComponent,
    CardSubtitleComponent,
    CardContentComponent,
    CardActionsComponent,
    CardComponent,
  ],
  imports: [CommonModule, IconModule, RouterModule],
})
export class CardModule {}
