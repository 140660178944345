import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { RouterModule } from '@angular/router';
import { AccordionComponent } from './accordion/accordion.component';
import { ButtonComponent } from './button/button.component';

import { ItemCardComponent } from './item-card/item-card.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { PoopCardComponent } from './poop-card/poop-card.component';
import { StatBadgeComponent } from './stat-badge/stat-badge.component';
import { TopbarComponent } from './topbar/topbar.component';
import { PrimengModule } from '../primeng.module';
import { IconComponent } from './icon/icon.component';
import { IconService } from './icon/icon.service';
import { InputComponent } from './input/input.component';
import { StatSystemComponent } from './poop-card/stat-system/stat-system.component';
import { DataViewComponent } from './data-view/data-view.component';
import { ListingInfoComponent } from './listing-info/listing-info.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JumboComponent } from './jumbo/jumbo.component';
import {
  BlockableDiv,
  BlockableDivComponent,
} from './blockable-div/blockable-div.component';
import { SpeeddialComponent } from './speedial/speeddial.component';
import { SpeedDialModule } from 'primeng/speeddial';
import { BannerComponent } from './banner/banner.component';
import { StatChartComponent } from './stat-chart/stat-chart.component';

import { FieldsetComponent } from './fieldset/fieldset.component';
import { DialogComponent } from './dialog/dialog.component';
import { ShowAddressComponent } from './show-address/show-address.component';
import { LandingPageModule } from '../landing-page/landing-page.module';
import { FrameModule } from './frames/frame.module';
import { CardModule } from './card/card.module';
import { IconModule } from './icon/icon.module';
import { CardActionsComponent } from './card/card-actions/card-actions.component';
import { CardContentComponent } from './card/card-content/card-content.component';
import { CardSubtitleComponent } from './card/card-subtitle/card-subtitle.component';
import { CardTitleComponent } from './card/card-title/card-title.component';
import { CardComponent } from './card/card.component';
import { SpeeddialModule } from './speedial/speeddial.module';

@NgModule({
  declarations: [
    TopbarComponent,
    PoopCardComponent,

    StatBadgeComponent,
    ItemCardComponent,
    LoadingSpinnerComponent,
    ButtonComponent,
    InputComponent,
    StatSystemComponent,
    DataViewComponent,
    ListingInfoComponent,
    JumboComponent,
    BlockableDivComponent,
    BlockableDiv,
    BannerComponent,
    StatChartComponent,

    FieldsetComponent,
    DialogComponent,
    ShowAddressComponent,
  ],
  providers: [],
  exports: [
    TopbarComponent,
    PoopCardComponent,

    StatBadgeComponent,
    ItemCardComponent,
    LoadingSpinnerComponent,
    ButtonComponent,
    InputComponent,
    StatSystemComponent,
    DataViewComponent,
    ListingInfoComponent,
    FormsModule,
    NgSelectModule,
    BlockableDivComponent,
    IconComponent,
    FieldsetComponent,
    JumboComponent,
    BannerComponent,
    StatChartComponent,
    DialogComponent,
    ShowAddressComponent,
    CardTitleComponent,
    CardSubtitleComponent,
    CardContentComponent,
    CardActionsComponent,
    CardComponent,
    SpeeddialComponent,
  ],
  imports: [
    CommonModule,
    PrimengModule,
    RouterModule,
    FormsModule,
    FrameModule,
    CardModule,
    IconModule,
    SpeeddialModule,
    NgSelectModule,
    ReactiveFormsModule,
  ],
})
export class ComponentsModule {}
