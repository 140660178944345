<div class="bg-frame">

  <div  class="bg-frame-wrapper">
    <svg
    class="frame"
    width="2378.2415"
    height="503.97623"
    viewBox="0 0 2378.2415 503.97623"
    fill="none"
    version="1.1"

    id="svg86"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg"
  >
    <path
    class="path dash-array-path"
    d="m 4.1524902,-338.89548 -51.7412002,51.74119 v 273.533463 l 25.586,25.58599 V 213.4504 l -24.18559,24.18559 v 798.25111 l -17.55862,30.4124"
      id="path74"
    />
    <path
    class="path dash-array-path"
    d="m 2376.2037,-338.12924 -0.4266,204.70305 50.0322,123.6204527 -1.0698,1037.1521373 30.3798,52.6195"
      id="path1381"
    />

    <path
    class="path regular-path"
    d="M -8.190918e-7,18.766613 H 878.45443 L 964.6818,168.11681 h 95.8095 l 113.4723,196.53978 h 900.06 297.5968"

    />
    <path
    class="path regular-path"
    d="M 1.9699592,110.3947 H 747.97258 l 117.47683,203.47584 h 240.68819 l 109.4689,189.60571 h 78.218 l 115.343,-66.59328 h 969.0739"

    />
    <path
    class="path dash-array-path"
    d="M 341.94525,16.297563 400.90261,0.49999271 H 534.57637 L 658.37829,124.3019 h 67.41513 49.72745 l 37.36265,64.714 v 31.90257"

    />
    <path
    class="path dash-array-path"
    d="m 999.8757,168.21639 73.1415,-126.684757 h 171.6795 l 212.7622,368.514937 h 216.1215 l 15.5364,26.90987"

    />
    <path
      class="path dash-array-path"
      d="m 1706.6455,440.77149 15.3384,-26.56677 h 139.8297 l 134.09,-77.41687 h 144.0477 l 46.1803,26.6622"

    />
    <path
    class="path dash-array-path"
    d="m 346.55031,117.9105 168.22427,291.37297 h 707.98852 l 81.9549,-47.31669"

  />
  </svg>
  <ng-content>

  </ng-content>
  </div>

</div>
