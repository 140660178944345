<div class="simple-frame" >
  <svg
  class="frame-right"
  width="49.917969"
  height="1658.1403"
  viewBox="0 0 49.917969 1658.1403"
  fill="none"
   xmlns:svg="http://www.w3.org/2000/svg">

  <path
  class="path regular-path"

  d="m 45.402474,21.470099 -25.8646,25.864575 V 525.28677 l 9.1975,15.93069 V 788.06258 L 4.50007383,816.29795 v 51.58993 L 38.772174,906.16004 v 384.67866 l -21.6333,21.6334 v 104.1539 l 19.7301,19.4173 v 209.0479 l 12.6955,12.6955"
  />
  <path
  class="path dash-array-path"

  d="m 17.215974,1309.2677 v -117.1289 l 20.4763,-20.4762"
  />
  <path
  class="path dash-array-path"

  d="m 17.511174,1416.8935 v 116.9753 l 18.8974,18.8974"
  />
  <path
  class="path dash-array-path"

  d="m 38.541774,1091.6436 -18.5627,-18.5627 V 907.06395 L 4.0653738,891.15024 v -18.70181"
  />
  <path
  class="path dash-array-path"

  d="M 19.562674,525.7729 V 796.33662"
  />
  <path
  class="path dash-array-path"

  d="M 19.725074,106.56782 4.9907738,91.833543 V 32.709836 L 37.347074,0.35355193"
  />
</svg>
<svg
class="frame-left"
width="49.917969"
height="1658.1403"
viewBox="0 0 49.917969 1658.1403"
fill="none"
 xmlns:svg="http://www.w3.org/2000/svg">

<path
class="path regular-path"

d="m 0.5156738,1636.6702 25.8646002,-25.8646 v -477.9521 l -9.1975,-15.9307 V 870.07763 l 28.2353,-28.23537 v -51.58993 l -38.2721,-38.27216 V 367.30155 l 21.6333,-21.6334 v -104.1539 l -19.7301,-19.4173 V 13.049052 L 0.35367383,0.35355193"
/>
<path
class="path dash-array-path"

d="m 32.702174,348.87255 v 117.1289 l -20.4763,20.4762"
/>
<path
class="path dash-array-path"

d="m 32.406974,241.24675 v -116.9753 l -18.8974,-18.8974"
/>
<path
class="path dash-array-path"

d="m 11.376374,566.49665 18.5627,18.5627 v 166.01691 l 15.9137,15.91371 v 18.70181"
/>
<path
class="path dash-array-path"

d="M 30.355474,1132.3674 V 861.80359"
/>
<path
class="path dash-array-path"

d="m 30.193074,1551.5724 14.7343,14.7343 v 59.1237 l -32.3563,32.3563"
/>
</svg>

<ng-content></ng-content>

</div>
