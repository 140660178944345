import { NgModule } from '@angular/core';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { RippleModule } from 'primeng/ripple';

import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ConfirmationService } from 'primeng/api';
import { ChartModule } from 'primeng/chart';

import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ScrollTopModule } from 'primeng/scrolltop';
import { BlockUIModule } from 'primeng/blockui';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TooltipModule } from 'primeng/tooltip';
import { CheckboxModule } from 'primeng/checkbox';

@NgModule({
  exports: [
    DialogModule,
    InputTextModule,
    CheckboxModule,
    InputNumberModule,
    RippleModule,
    ConfirmPopupModule,
    ChartModule,
    ConfirmDialogModule,
    RadioButtonModule,
    ScrollTopModule,
    BlockUIModule,
    ProgressSpinnerModule,
    TooltipModule,
  ],
  providers: [ConfirmationService],
})
export class PrimengModule {}
